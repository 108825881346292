
@import url('http://fonts.cdnfonts.com/css/mollen-personal-use');

html{
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Mollen Personal Use', sans-serif;
  color: #666666;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255, 255, 255);
}

code {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
   
}

/* Utilities */

.red{
  color: rgb(205, 0, 1);
}

.light{
  font-weight: 100;
}
.bold{
  font-weight: 500;
}

.container{
  padding: 0 100px;
}

/* Specific styling for html elements */

a{
  text-decoration: none;
}

h1{
  font-size: 57px;
  line-height: 67px;
}

h2{
  font-size: 40px;
  line-height: 48px;
}

h3{
  font-size: 30px;
  line-height: 36px;
}

h4{
  font-size: 17px;
  line-height: 20px;
}
h1, h2, h3, h4, h5, h6{
  color: #1a191d;
}
p{
  font-size: 15px;
  line-height: 24px;
}

input{
  outline:none;
}



/* second part of utilities */
.red-btn{
    font-weight: 700;
    color: white;
    background: rgb(205, 0, 1);
    text-align: center;
    padding: 8px;
    font-weight: 700;
    display: inline-block;
    transition: all 0.5s;
}

.red-btn:hover{
  background: white;
  color: rgb(205, 0, 1);
  transition: all 0.5s;
  border-radius: 0 10px 0 10px;
}


.white-btn{
    font-weight: 700;
    color: rgb(26, 25, 29);
    background: white;
    text-align: center;
    padding: 8px;
    font-weight: 700;
    transition: all 0.5s;
}

.white-btn:hover{
  background: rgb(26, 25, 29);
  color: white;
  transition: all 0.5s;
  border-radius: 0 10px 0 10px;
}

.header__bullet{
  display: inline-block;
  width: 5px;
  height: 5px;
  background: rgb(205, 0, 1);
  margin-right: 5px;
}

.carousel-btn{
  width: 50px;
  height: 5px;
  margin-right:10px;
  border: none;
  background: rgb(223, 0, 0);
}
.carousel-btn:disabled{
  background: red;
}
.carousel-btn:enabled{
  width: 25px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 0 auto;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.header-caption{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.caption-hr{
    background: rgb(222, 222, 222);
    height: 3px;
    display: flex;
    width: 300px;
    justify-content: center;
}

.caption-hr-two{
  background: rgb(222, 222, 222);
  height: 3px;
  display: flex;
  width: 200px;
  justify-content: start;
  align-items: center;
}
.caption-hr span, .caption-hr-two span{
  display: inline-block;
  height: 5px;
  width: 50%;
  background: rgb(205, 0, 1);
}

.card{
    display: flex;
    flex-direction: column;
    background: white;
    align-items: center;
    text-align: center;
    padding: 50px 20px;
    border: 1px solid rgb(222, 222, 222);
    margin: 50px 0;
    position: relative;
    justify-content: space-between;
}

.card h4{
    margin: 20px 0;
}

.card p{
  color: #666666;
}

.card-btn{
    display: inline-block;
    width: 100%;
    color: rgb(102, 102, 102);
    margin-top: 20px;
    border: 1px solid rgb(222, 222, 222);
    padding: 10px 0;
    transition: all 0.5s;
}
.card-btn:hover{
  background: rgb(205, 0, 1);
  color: #fff;
  border-radius: 0 10px 0 10px;
}
.card span{
  font-size: 30px;
  color: #fff;
  background:  #ED7014;
  display: inline-block;
  padding: 20px 30px;
  position: absolute;
  top: -40px;
}




/* styles */

.italic{
  font-style: italic;
}


/* Media Queries */


@media only screen and (max-width: 1200px){
  .container{
    padding: 0 50px !important;
  }

  h1{
    font-size: 25px;
  }
      /*Tablets [601px -> 1200px]*/
}


@media only screen and (max-width: 600px){
/*Big smartphones [426px -> 600px]*/

    .container{
      padding: 0 20px !important;
    }

    h1{
      font-size: 35px;
      line-height: 42px;
    }
    h2{
      font-size: 33px;
      line-height: 40px;
    }
    p{
      font-size: 15px;
      line-height: 24px;
    }
    h3{
      font-size: 17px;
      line-height: 20px;
    }
    h4{
      font-size: 23px;
      line-height: 28px;
    }
    h5{
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: rgb(26, 25, 29);
    }



   
}

/* Blog section */



@media only screen and (max-width: 425px){
/*Small smartphones [325px -> 425px]*/
    .container{
      padding: 0 20px !important;
    }
    
    h1{
      font-size: 35px;
      line-height: 42px;
    }
    p{
      font-size: 11px;
      line-height: 16px;
    }

}